<template>
	<div class="container">
		<div class="lang">
			<el-select v-model="value" placeholder="กรุณาเลือก" @change="clickChange">
				<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
			</el-select>
		</div>
		<h2>โปรโตคอลการถ่ายทอดสด "UniLive"</h2>
		<p>โปรโตคอล UniLive Live เป็น Pix Technology PTE. LTD.
			ข้อตกลงทางอิเล็กทรอนิกส์ที่มีผลตามกฎหมายที่กำหนดสิทธิและหน้าที่ของทั้งสองฝ่ายที่ตกลงกันโดย LTD. ("เรา") และคุณ
			(คุณเป็นบุคคลธรรมดานิติบุคคลหรือองค์กรอื่น ๆ ) ต่อไปนี้จะเรียกว่า "ข้อตกลงนี้" เมื่อท่านทำเครื่องหมาย "ฉันยอมรับ"
			หรือคลิกที่ปุ่ม "ฉันได้อ่านและปฏิบัติตามข้อตกลงนี้แล้ว" แสดงว่าท่านได้อ่านอย่างรอบคอบ เข้าใจอย่างถ่องแท้
			และยอมรับเงื่อนไขทั้งหมดของข้อตกลงนี้โดยไม่มีการสงวนสิทธิ์
		</p>
		<h3>กฎพื้นฐานสำหรับการไลฟ์สดของ Uni Live</h3>
		<p>
			เนื้อหาของการไลฟ์สด
		</p>
		<p>
			1. ข้อมูลที่ผิดกฎหมายและเป็นอันตราย  
			ห้ามเผยแพร่เนื้อหาที่เกี่ยวข้องกับประเด็นทางการเมืองที่ละเอียดอ่อน การแบ่งแยกเชื้อชาติ หรือแนวคิดสุดโต่งทางศาสนา  
			ห้ามแสดงภาพความรุนแรง เลือดสาด ความหวาดกลัว การพนัน ยาเสพติด หรือกิจกรรมที่ผิดกฎหมายใด ๆ  
			ห้ามส่งเสริมความเชื่องมงาย (เช่น การทำนายโชคชะตา) ควรนำเสนออย่างระมัดระวังและไม่ให้เกิดการโฆษณาเกินจริง  
		</p>
		<p>
			2. เนื้อหาลามกและไม่เหมาะสม  
			ห้ามเผยแพร่เนื้อหาที่เป็นภาพเปลือย การสื่อถึงเรื่องเพศ การแสดงออกที่ไม่เหมาะสม หรือเนื้อหาลามกในทุกรูปแบบ  
			การแต่งกายต้องไม่เปิดเผยมากเกินไป (เช่น เปิดหน้าอก เปิดก้น เสื้อผ้าโปร่งแสง) มิฉะนั้น การไลฟ์สดอาจถูกระงับ  
			ห้ามใช้ภาษาหรือพฤติกรรมที่สื่อถึงเรื่องเพศในลักษณะยั่วยุ  
		</p>
		<p>
			3. การละเมิดสิทธิ์ของผู้อื่น  
			ห้ามเผยแพร่ข้อมูลส่วนบุคคลของผู้อื่นโดยไม่ได้รับอนุญาต (เช่น ชื่อ ที่อยู่)  
			ห้ามกลั่นแกล้งทางออนไลน์ โจมตีผู้อื่น หรือใส่ร้ายป้ายสีผู้อื่น  
		</p>
		<p>
			4. การฉ้อโกงและข้อมูลเท็จ  
			ห้ามเผยแพร่ข่าวลือ โฆษณาเท็จ หรือข้อมูลที่ไม่ได้รับการยืนยัน (เช่น การกล่าวอ้างเกินจริงเกี่ยวกับผลิตภัณฑ์สุขภาพ)  
			ห้ามกระทำการฉ้อโกง แชร์ลูกโซ่ หรือชักชวนให้ผู้ใช้ทำธุรกรรมส่วนตัว  
		</p>
		<p>
			5. เนื้อหาที่เกี่ยวข้องกับผู้เยาว์  
			ต้องปกป้องเยาวชนอย่างเคร่งครัด เด็กไม่สามารถเข้าร่วมไลฟ์สดได้  
			ห้ามเผยแพร่เนื้อหาที่อาจเป็นอันตรายต่อสุขภาพกายหรือจิตใจของเยาวชน  
		</p>

		<h3>กฎพื้นฐานสำหรับการอัปโหลดวิดีโอบน Uni Live</h3>
		<p>
			แนวทางเนื้อหา (ข้อบังคับของชุมชน)
		</p>
		<p>
			ห้ามเนื้อหารุนแรง: ห้ามอัปโหลดวิดีโอที่แสดงความรุนแรง การทำร้าย การทารุณกรรม หรือการก่อการร้าย รวมถึงฉากที่จำลองความรุนแรง  
		</p>
		<p>
			ห้ามเนื้อหาลามกหรือโจ่งแจ้ง: ห้ามเนื้อหาที่เกี่ยวข้องกับภาพเปลือย การสื่อถึงเรื่องเพศ หรือเนื้อหาสำหรับผู้ใหญ่ในทุกรูปแบบ  
		</p>

		<p>
			ห้ามข้อความแสดงความเกลียดชัง: ห้ามเผยแพร่เนื้อหาที่มีการเลือกปฏิบัติ การโจมตี หรือการปลุกปั่นให้เกิดความเกลียดชังต่อกลุ่มใด ๆ ตามเชื้อชาติ ศาสนา เพศ รสนิยมทางเพศ หรือสัญชาติ  
		</p>
		<p>
			ห้ามการกลั่นแกล้งและคุกคาม: ห้ามอัปโหลดเนื้อหาที่เป็นการดูถูก ข่มขู่ หรือคุกคามผู้อื่น รวมถึงพฤติกรรมที่ไม่เหมาะสมต่อเด็ก  
		</p>
		<p>
			ห้ามพฤติกรรมที่เป็นอันตราย: ห้ามแสดงเนื้อหาที่อาจทำให้เกิดอันตราย เช่น การท้าทายอันตราย การเล่นพิเรนทร์ หรือกิจกรรมที่ผิดกฎหมาย (เช่น การใช้ยาเสพติด อาวุธ)  
		</p>

		<h3>ทั่วไป 1 </h3>
		<p>1, UniLive Platform หมายถึง Pix Technology PTE. LTD. และบริษัทในเครือ ซึ่งรวมถึงแต่ไม่จำกัดเพียง Pix Technology PTE. LTD.
			บจก.มีแพลตฟอร์มการถ่ายทอดสดของตัวเอง คุณสมัครเป็นผู้ให้บริการถ่ายทอดสดของเรา (หรือ "ฝ่ายถ่ายทอดสด") บนแพลตฟอร์ม
			UniLive (ต่อไปนี้จะเรียกว่า "แพลตฟอร์ม") ที่ดำเนินการโดยชอบด้วยกฎหมายของเรา
			ตามข้อกำหนดและกฎเกณฑ์การลงทะเบียนของเรา เพื่อให้บริการถ่ายทอดสดเนื้อหาวิดีโอสดออนไลน์แก่ผู้ใช้แพลตฟอร์มของเรา
			คุณจะถือเป็นระยะเวลาของข้อตกลงระหว่างการให้บริการของแพลตฟอร์มของเรา
			เราไม่ได้ตรวจสอบเนื้อหาวิดีโอดังกล่าวที่อัปโหลดและมีส่วนร่วมแก้ไขและผลิตโดยคุณล่วงหน้า และไม่ได้ดําเนินการแก้ไข
			จัดระเบียบ แก้ไข และประมวลผลวิดีโอดังกล่าวโดยสมัครใจ
		</p>
		<p>
			2. ก่อนการลงนามในข้อตกลงนี้ คุณได้เข้าใจกฎและข้อกำหนดต่าง ๆ ของเราอย่างถ่องแท้ และมีเงื่อนไขและความสามารถ
			มีคุณสมบัติในการปฏิบัติหน้าที่และภาระผูกพันของฝ่ายถ่ายทอดสดตามที่ตกลงไว้ในข้อตกลงนี้
			ข้อตกลงนี้เป็นภาระหน้าที่ตามกฎหมายที่มีผลผูกพันและบังคับใช้ได้สำหรับคู่สัญญาของคุณ
			และคุณได้รับทราบอย่างชัดเจนและไม่มีข้อสงสัยเกี่ยวกับเนื้อหาของข้อกำหนดและคำจำกัดความทั้งหมดภายใต้ข้อตกลงนี้
		</p>
		<p>3
			คุณให้คำมั่นสัญญาและประกาศว่าการให้บริการแก่เราสอดคล้องกับบทบัญญัติที่เกี่ยวข้องของกฎหมายท้องถิ่นและจะไม่มีส่วนร่วมในการละเมิดบทบัญญัติอื่น
			ๆ ของกฎหมายจีนและท้องถิ่นในนามของการปฏิบัติตามข้อตกลงนี้ </p>
		<p>4, คุณและเราไม่ก่อให้เกิดการจ้างงาน, แรงงาน, แรงงานสัมพันธ์ในระดับกฎหมายแรงงานใด ๆ
			และเราไม่จำเป็นต้องจ่ายเงินประกันสังคมและผลประโยชน์ให้กับคุณ</p>
		<p>5 ห้ามมิให้ท่านกระทำการใดๆ
			ที่เกี่ยวข้องกับการถ่ายทอดสดบนแพลตฟอร์มการแข่งขันของบุคคลที่สามโดยไม่ได้รับความยินยอมเป็นลายลักษณ์อักษรจากเราก่อน
		</p>
		<p>6, คุณได้รับการยืนยันก่อนที่จะลงทะเบียนเป็นผู้ประกาศข่าวของ UniLive หรือให้บริการถ่ายทอดสดบนแพลตฟอร์ม UniLive
			โดยไม่มีการลงนามในข้อตกลงการถ่ายทอดสดพิเศษกับแพลตฟอร์มของบุคคลที่สามใด ๆ หรือมีอยู่ของการดำรงอยู่ที่ถูกต้อง</p>
		<p>7 สิทธิในการถ่ายทอดสด อัปโหลด เผยแพร่ หรือส่งต่อเนื้อหาอื่น ๆ ระหว่างความร่วมมือระหว่างทั้งสองฝ่าย
			มีผลบังคับใช้ตามข้อตกลงในข้อตกลงการให้บริการผู้ใช้ UniLive </p>
		<h3>ข้อ 2 ภาระผูกพันตามสิทธิของเรา</h3>
		<p>1 เรามีสิทธิที่จะกำหนดระบบการดำเนินงานของแพลตฟอร์มและกฎการจัดการของฝ่ายถ่ายทอดสด และถือเป็นส่วนหนึ่งของข้อตกลงนี้
			มีสิทธิในการจัดการและดูแลคุณ มีสิทธิในการปรับเปลี่ยนหรือเปลี่ยนแปลงกฎที่สอดคล้องกันตามสถานการณ์การดำเนินงาน
			ซึ่งคุณเข้าใจและเห็นด้วยกับเรื่องนี้</p>
		<p>
			2. เรามีสิทธิ์ในการตรวจสอบและตัดสินฝ่ายของคุณเพื่อสร้าง (ยกเลิก) รางวัลหรือบทลงโทษสำหรับฝ่ายคุณ
			โครงการและมาตรฐานการตรวจสอบที่เฉพาะเจาะจงจะถูกกำหนดโดยเราเป็นอย่างอื่นโดยไม่ต้องขอความยินยอมจากคุณเพิ่มเติม
			ในกรณีที่เราร้องขอข้อตกลงความร่วมมือในการถ่ายทอดสดอย่างเป็นทางการกับคุณแยกต่างหาก
			คุณจะไม่สามารถปฏิเสธหรือปฏิเสธในรูปแบบอื่น ๆ [มิฉะนั้นคุณจะจ่ายค่าผิดนัด [50000] (ตัวพิมพ์ใหญ่: Wuwan) RMB]
			ให้กับเราในครั้งเดียว
		</p>
		<p>
			3、
			เรามีสิทธิที่จะให้ข้อเสนอแนะและความคิดเห็นเกี่ยวกับการปรับปรุงบริการถ่ายทอดสดของคุณและคุณจะต้องดำเนินการแก้ไขที่สอดคล้องกันภายใน
			[3] วันทำการหลังจากได้รับข้อเสนอแนะและความคิดเห็นจากเรามิฉะนั้นเรามีสิทธิที่จะจำกัดปิดรีไซเคิลหรือยกเลิกการใช้
			UniLive Live Room ของคุณ การหักค่าบริการ (ถ้ามี)
			ที่จะต้องจ่ายให้กับคุณอาจสร้างความเสียหายให้กับคุณและความสูญเสียนั้นเป็นความรับผิดชอบของคุณเองและเราจะไม่รับผิดชอบใด
			ๆ
		</p>
		<p>4 เรามีอำนาจในการตัดสินใจขั้นสุดท้ายในเรื่องที่เกี่ยวข้องกับการถ่ายทอดสดที่ดำเนินการโดยคุณ</p>
		<p>5 เรามีสิทธิ์ที่จะใช้ชื่อของคุณ (รวมถึงแต่ไม่จำกัดเพียงชื่อจริง นามปากกา ชื่อออนไลน์ สัญลักษณ์ใดๆ
			ที่ใช้ชื่อและข้อความที่แสดงถึงตัวตนของคุณ) ภาพบุคคล (รวมถึงแต่ไม่จำกัดเฉพาะภาพบุคคลจริงและภาพการ์ตูน เป็นต้น)
			เพื่อประชาสัมพันธ์ทุกประเภทบนแพลตฟอร์มของเรา</p>
		<p>6
			เรามีความรับผิดชอบในการให้การสนับสนุนบริการทางเทคนิคของแพลตฟอร์มในขณะที่รับผิดชอบในการชำระค่าบริการของแพลตฟอร์มหากมี
		</p>
		<h3>ข้อที่ 3 หน้าที่ตามสิทธิของคุณ</h3>
		<p>
			1.
			คุณควรใช้ข้อมูลระบุตัวตนที่แท้จริงและข้อมูลส่วนบุคคลจะต้องไม่ลงทะเบียนและรับรองด้วยข้อมูลประจำตัวผู้พำนักปลอมข้อมูลการลงทะเบียนองค์กรข้อมูลรหัสองค์กรปลอม
			หากมีอะไรเปลี่ยนแปลงในโปรไฟล์ของคุณคุณควรอัปเดตให้ทันเวลา เราห้ามไม่ให้ผู้เยาว์สตรีมสด
			หากผู้ปกครองตามกฎหมายต้องการให้ผู้เยาว์สามารถให้บริการถ่ายทอดสดทางเว็บและบริการถ่ายทอดสดตามที่ตกลงไว้ในข้อตกลงนี้
			ต้องตัดสินในฐานะผู้ปกครองว่าเนื้อหาของบริการดังกล่าวเหมาะสมกับผู้เยาว์หรือไม่
			และผู้ปกครองตามกฎหมายต้องรับผิดชอบผลที่ตามมาทั้งหมด
			คุณรับรองว่าจะไม่เป็นอันตรายต่อผลประโยชน์ที่ชอบด้วยกฎหมายของบุคคลที่สามใด ๆ จากการบังคับใช้ข้อตกลงนี้
			การยอมรับและการปฏิบัติตามข้อตกลงนี้ของคุณไม่ละเมิดเอกสารทางกฎหมายใด ๆ
			ที่มีผลผูกพันกับคุณและจะไม่ทำให้เราต้องรับผิดต่อบุคคลที่สามใด ๆ
		</p>
		<p>2
			คุณรับผิดชอบเครือข่ายที่จำเป็นในการถ่ายทอดสดอุปกรณ์ที่รองรับวิดีโอและเสียงและรับประกันภาพสดที่ชัดเจนคุณภาพเสียงที่ชัดเจนและมีเสถียรภาพ
		</p>
		<p>3
			คุณยินดีที่จะปฏิบัติตามขั้นตอนการสมัครห้องสดที่กำหนดโดยแพลตฟอร์มส่งเอกสารการสมัครที่จำเป็นสำหรับแพลตฟอร์มและจ่ายเงินประกันที่เกี่ยวข้องโดยสมัครใจ
		</p>
		<p> 4 คุณสัญญาว่าห้องสดจะต้องใช้สำหรับการถ่ายทอดสดและไม่ใช้ในกิจกรรมอื่น ๆ ที่ไม่ใช่ลักษณะการถ่ายทอดสด <b>
				เมื่อท่านดำเนินการเทปถ่ายทอดสด
				ท่านและซัพพลายเออร์ของท่านควรปฏิบัติตามบทบัญญัติของกฎหมายจีนและปฏิบัติตามบทบัญญัติที่เกี่ยวข้องของข้อตกลงการให้บริการร้านค้าที่ออกโดยเรา
			</b>
		</p>
		<p>
			5. ไม่มีข้อมูล / ข้อมูล / คำพูด / เนื้อหา ฯลฯ ที่คุณดำเนินการถ่ายทอดสดภายใต้ข้อตกลงนี้และ /
			หรือข้อมูลทั้งหมดที่โพสต์บนแพลตฟอร์มนี้จะต้องไม่มีเนื้อหาใด ๆ
			ที่ละเมิดกฎหมายระเบียบและข้อบังคับที่เกี่ยวข้องของสาธารณรัฐประชาชนจีนรวมถึงแต่ไม่ จำกัด
			เฉพาะเนื้อหาที่เป็นอันตรายต่อความมั่นคงของชาติลามกอนาจารเท็จผิดกฎหมายหมิ่นประมาท (รวมถึงการหมิ่นประมาททางการค้า)
			การข่มขู่หรือการล่วงละเมิดที่ผิดกฎหมายการละเมิดทรัพย์สินทางปัญญาของผู้อื่นสิทธิส่วนบุคคลความลับทางการค้าหรือสิทธิและผลประโยชน์อื่น
			ๆ ที่ชอบด้วยกฎหมายและเนื้อหาที่ขัดต่อความสงบเรียบร้อยและศีลธรรมหรือลิงก์ไปยังเนื้อหาเหล่านี้
		</p>
		<p>
			6. ท่านมุ่งมั่นที่จะรักษาภาพลักษณ์ของแพลตฟอร์มของเราและแพลตฟอร์มของเราอย่างแข็งขัน
			และท่านจะไม่กระทำการใดอันเป็นอันตรายต่อภาพลักษณ์หรือผลประโยชน์ของเราและ / หรือแพลตฟอร์มของเรา
			ในระหว่างระยะเวลาของข้อตกลงนี้และหลังจากสิ้นสุดข้อตกลง
			ท่านจะไม่บอกเป็นนัยหรือโพสต์ข้อความที่ไม่เป็นประโยชน์ต่อเราและ / หรือแพลตฟอร์มของเราผ่านช่องทางใด ๆ (รวมถึง แต่ไม่
			จำกัด เฉพาะเว็บไซต์ บล็อก Weibo WeChat กลุ่มแชท QQ การชุมนุมของผู้เล่น ฯลฯ )
		</p>
		<p>
			7. ห้ามมิให้คุณอ้างอิงข้อมูลที่เกี่ยวข้องกับแพลตฟอร์มการแข่งขันของบุคคลที่สามในรูปแบบใด ๆ (รวมถึง แต่ไม่ จำกัด
			เพียงข้อความ, ช่องปาก, แพทช์วิดีโอ ฯลฯ ) ไม่ว่าในโอกาสใด ๆ โดยไม่ได้รับความยินยอมเป็นลายลักษณ์อักษรจากเรา
			ห้ามชี้นำหรือให้ข้อมูลหรืออำนวยความสะดวกใด ๆ แก่ผู้ใช้ที่มีอยู่ของแพลตฟอร์มของเรา ผู้ถ่ายทอดสดอื่น ๆ
			และพนักงานของเราในการเข้าสู่แพลตฟอร์มการแข่งขันของบุคคลที่สามอื่น ๆ รวมถึงแต่ไม่ จำกัด
			เพียงการให้ความช่วยเหลือในการติดต่องานชักชวน ฯลฯ
		</p>
		<h3>ข้อ 4 ค่าธรรมเนียมบริการและการชำระราคา </h3>
		<p>
			1. ขึ้นอยู่กับว่าคุณเป็นผู้ให้บริการถ่ายทอดสดสำหรับผู้ใช้แพลตฟอร์มผู้ใช้อาจใช้จ่ายของขวัญเสมือนจริงแก่คุณ
			คุณอาจยื่นขอชำระรายได้ที่เกี่ยวข้อง (ถ้ามี)
			ตามข้อกำหนดและกฎเกณฑ์การตั้งถิ่นฐานของเราและเราจะจ่ายให้กับคุณหลังจากหักภาษีแล้ว
			สำหรับการบริโภคของขวัญเสมือนจริงที่ได้มาโดยวิธีการที่ผิดปกติเรามีสิทธิที่จะตัดสินและจัดการอย่างอิสระ
		</p>
		<p>2 ค่าบริการที่คุณได้รับจะต้องเสียภาษีตามกฎหมายและระเบียบข้อบังคับของประเทศที่เกี่ยวข้อง
			และเราจะชำระค่าบริการที่คุณได้รับในบัญชีธนาคารที่คุณกรอกลงในศูนย์ผู้ใช้
			ซึ่งคุณอาจสอบถามข้อมูลที่เกี่ยวข้องในบันทึกรายได้หลังจากลงจอดบนแพลตฟอร์มของเรา
			(ข้อมูลการชำระหนี้เป็นข้อมูลที่รวมภาษีแล้ว)</p>
		<p>
			3、
			หากคุณเป็นบุคคลธรรมดาและได้ลงทะเบียนบนแพลตฟอร์มของเราและผ่านการรับรองส่วนบุคคลเรามีสิทธิที่จะจ่ายค่าบริการที่คุณได้รับในบัญชีธนาคารส่วนบุคคลที่คุณกรอกในศูนย์ผู้ใช้
			หากคุณเป็นนิติบุคคลหรือองค์กรอื่น ๆ และจดทะเบียนบนแพลตฟอร์มของเราและได้รับการรับรองจากสถาบัน
			เรามีสิทธิ์ในการชำระค่าบริการที่คุณได้รับในบัญชีสถาบันที่คุณกรอกไว้ในหน้าการรับรองของสถาบัน
			แต่คุณจะต้องให้ใบแจ้งหนี้พิเศษ VAT ที่ถูกต้องเท่าเทียมกันกับเราภายใน 5 วันทำการก่อนการชำระเงินของเรา
			(ชื่อใบแจ้งหนี้คือค่าบริการถ่ายทอดสด)
			ความล่าช้าในการชำระเงินของเราเนื่องจากความล่าช้าในการจัดหาใบแจ้งหนี้ของคุณไม่ถือเป็นการผิดนัดของเรา
			เราจะชำระค่าบริการตามบัญชีที่ท่านได้กรอกไว้
			และถือว่าเราได้ปฏิบัติตามภาระผูกพันในการชำระเงินที่ตกลงกันไว้ในข้อตกลงนี้แล้ว หากคุณเป็นนิติบุคคลหรือองค์กรอื่น ๆ
			ค่าใช้จ่ายที่เกิดขึ้นจากการจัดการและการใช้งานบัญชีและห้องถ่ายทอดสดของพนักงานหรือผู้ประกาศข่าวของคุณจะถูกตัดสินระหว่างคุณและเจ้าหน้าที่หรือผู้ประกาศข่าวของคุณ
			ในกรณีที่คุณก่อให้เกิดข้อพิพาท การฟ้องร้อง หรือการชดใช้ค่าเสียหายอันเนื่องมาจากการชำระค่าใช้จ่ายดังกล่าวแก่เรา
			(รวมถึงแต่ไม่จำกัดเฉพาะเงินที่เราจ่ายเงินเดือนล่วงหน้าเมื่อคุณผิดนัดชำระค่าจ้างของพนักงานหรือผู้ประกาศข่าวของคุณ)
			เรามีสิทธิที่จะหักค่าบริการที่ชำระไปก่อนและส่วนที่ไม่เพียงพอเรามีสิทธิที่จะเรียกคืนจากคุณ
		</p>
		<p>4 คุณรับประกันว่าข้อมูลบัญชีที่กรอกนั้นถูกต้องเป็นจริงและถูกต้องเช่นข้อผิดพลาดในการชำระเงินใด ๆ
			ที่เกิดจากข้อมูลบัญชีเป็นความรับผิดชอบของคุณแต่เพียงผู้เดียว ในขณะเดียวกัน หากคุณต้องการเปลี่ยนแปลงข้อมูลบัญชี
			คุณต้องแจ้งให้เราทราบเป็นลายลักษณ์อักษรในเวลาที่เหมาะสม
			และข้อมูลบัญชีใหม่จะมีผลบังคับใช้ในเดือนถัดไปหลังจากที่คุณส่งใบสมัครและผ่านการตรวจสอบของเราแล้ว </p>
		<h3>ข้อ 5 ระบบการรักษาความลับ</h3>
		<p>1
			คุณควรปฏิบัติตามระบบการรักษาความลับของเราอย่างเคร่งครัดและสัญญาว่าจะเก็บความลับทางการค้าของเราไว้อย่างไม่มีกำหนด
			ท่านจะต้องชดใช้ค่าเสียหายแก่เรา ทั้งทางตรงและทางอ้อมต่อชื่อเสียง ชื่อเสียง หรือทางการเงิน
			อันเนื่องมาจากการที่ท่านใช้หรือเปิดเผยความลับทางการค้าและข้อมูลของเราโดยละเมิดข้อตกลง
			] ค่าผิดนัดชำระหนี้ (Meta Default) ซึ่งไม่เพียงพอต่อการชดใช้ค่าเสียหายของเรา ท่านจะต้องชดใช้ค่าเสียหายของเราด้วย
		</p>
		<p>
			2. ความลับทางการค้า หมายถึง ข้อมูลที่จัดทำโดยเรา หรือที่คุณได้เรียนรู้ในระหว่างความร่วมมือระหว่างทั้งสองฝ่าย
			หรือเรามีภาระผูกพันในการรักษาความลับต่อบุคคลที่สาม ซึ่งเกี่ยวข้องกับธุรกิจของเรา
			ซึ่งอาจก่อให้เกิดประโยชน์ทางเศรษฐกิจแก่เรา ข้อมูลที่เป็นประโยชน์ในทางปฏิบัติและไม่เป็นที่รู้จัก รวมถึง
			(แต่ไม่จำกัดเพียง): ข้อมูลทางเทคนิค ข้อมูลการดำเนินงาน และข้อมูลและเอกสารที่เกี่ยวข้องกับการบริหารของเรา
			(ซึ่งมีเนื้อหาของข้อตกลงนี้และข้อตกลงที่เกี่ยวข้อง) จำนวนเงินและวิธีการชำระค่าบริการที่คุณได้รับจากเรา หลักเกณฑ์
			วิธีการเป็นเจ้าของสิทธิ วิธีการมอบอำนาจ รายชื่อลูกค้า รายชื่อผู้ถ่ายทอดสดคนอื่น ๆ รายละเอียดการติดต่อ ค่าบริการ
			รายชื่อพนักงานของเรา ฯลฯ ไม่เป็นที่รู้จักของสาธารณชน ข้อมูลของ
		</p>
		<p>3 ท่านจะต้องปฏิบัติตามข้อตกลงนี้อย่างเคร่งครัด
			และจะไม่เป็นความลับทางการค้าต่อเราโดยไม่ได้รับอนุญาตเป็นลายลักษณ์อักษรหรือได้รับความยินยอมจากเรา</p>
		<ul>
			<li> (1) การเผยแพร่ การรั่วไหล ไม่ว่าในลักษณะใด ๆ ต่อบุคคลที่สามหรือสาธารณะที่ไม่เฉพาะเจาะจง </li>
			<li> (2) ใช้ความลับทางการค้าของเราเพื่อวัตถุประสงค์ที่ไม่ใช่วัตถุประสงค์ของข้อตกลงนี้</li>
		</ul>
		<p>4 เมื่อข้อตกลงนี้สิ้นสุดลง ท่านจะส่งคืนความลับทางการค้าของเราทั้งหมดให้แก่เรา
			หรือทำลายเอกสารทั้งหมดที่บันทึกความลับทางการค้าของเราภายใต้การดูแลของเรา</p>
		<p>5 บทบัญญัติของข้อนี้ยังคงมีผลบังคับใช้หลังจากสิ้นสุดข้อตกลงนี้ </p>
		<h3>การเปลี่ยนแปลง การยกเลิก การสิ้นสุดของข้อตกลงที่หก</h3>
		<p>
			1、 เรามีสิทธิ์ที่จะเปลี่ยนแปลง ระงับ ยุติข้อตกลงนี้ หากจำเป็น
			และแจ้งให้ทราบในหน้าที่เกี่ยวข้องว่าข้อตกลงที่เปลี่ยนแปลงจะมีผลแทนข้อตกลงเดิมเมื่อมีการประกาศในหน้าที่เกี่ยวข้อง
			เมื่อมีการเปลี่ยนแปลงข้อกำหนดของข้อตกลงนี้ หากคุณยังคงให้บริการถ่ายทอดสดแก่ผู้ใช้แพลตฟอร์มของเราอย่างต่อเนื่อง
			เช่น การถ่ายทอดสด จะถือว่าคุณทราบและยอมรับข้อตกลงที่เปลี่ยนแปลงไป ในกรณีที่คุณไม่เห็นด้วยกับการเปลี่ยนแปลงใด ๆ
			ที่เราทำกับข้อตกลงนี้คุณจะต้องแจ้งให้เราทราบเป็นลายลักษณ์อักษรทันทีและหยุดบริการถ่ายทอดสดใด ๆ
			ที่ดำเนินการบนแพลตฟอร์มของเรา
		</p>
		<p> 2 ข้อตกลงอาจถูกยกเลิกโดยทั้งสองฝ่ายเกี่ยวกับการยกเลิกข้อตกลงนี้ </p>
		<p>3 คุณมีกรณีใดกรณีหนึ่งต่อไปนี้และเราสามารถยกเลิกข้อตกลงนี้ได้ทันทีโดยไม่ต้องแจ้งให้ทราบล่วงหน้า: </p>
		<ul>
			<li> (1) เราพบว่าคุณละเมิดคำแถลงและสัญญาที่ให้ไว้กับข้อตกลงนี้ </li>
			<li>(2)
				อันเป็นผลมาจากการกระทำของท่านที่ก่อให้เกิดความเสียหายอย่างมีนัยสำคัญต่อผลประโยชน์ของเราทั้งโดยตรงและโดยอ้อม</li>
			<li>(3) การละเมิดกฎหมายและข้อบังคับของประเทศ;</li>
			<li> (4) การละเมิดภาระผูกพันอื่น ๆ ที่กำหนดไว้ในข้อตกลงนี้</li>
			<li> (5) การปฏิบัติตามข้อตกลงนี้ (แม้ว่าจะไม่ถือเป็นการผิดสัญญา) ในลักษณะเชิงลบ การละเว้น ฯลฯ
				ที่ไม่เป็นไปตามข้อกำหนดของเรา ซึ่งยังไม่ได้รับการแก้ไขภายใน 10 วันนับจากวันที่ได้รับแจ้งจากเรา </li>
			<li> (6)
				เนื่องจากการเกิดขึ้นของสถานการณ์ที่ผิดปกติที่เราเชื่อว่าคุณไม่เหมาะสมที่จะดำเนินการเกี่ยวกับการให้บริการภายใต้ข้อตกลงนี้และสถานการณ์ที่ผิดปกติยังไม่ได้รับการกำจัดภายใน
				10 วันนับแต่วันที่ได้รับแจ้งจากเรา </li>
			<li> (7) กรณีที่ไม่มีการประกอบธุรกิจบริการถ่ายทอดสดอีกต่อไปเนื่องจากการปรับธุรกิจของเรา</li>
		</ul>
		<p>4 เนื่องจากการยกเลิก การสิ้นสุดของข้อตกลง ซึ่งเกิดจากย่อหน้า 1 และ 2 ของข้อ 6 ของข้อตกลงนี้
			เราตกลงที่จะชำระค่าบริการกับคุณตามข้อ 4 ของข้อตกลงนี้และนโยบายแบบเรียลไทม์ของแพลตฟอร์มของเรา </p>
		<p>5 เนื่องจากการยกเลิก การสิ้นสุดของข้อตกลงที่เกิดจากย่อหน้า 3 ของข้อ 6 ของข้อตกลงนี้
			เรามีสิทธิที่จะหักค่าบริการทั้งหมดที่ยังไม่ได้ชำระในบัญชีของคุณ
			และมีสิทธิที่จะเรียกร้องให้คุณรับผิดชอบต่อการละเมิดตามที่ตกลงกันไว้</p>
		<h3>ข้อ 7 ความรับผิดสำหรับการละเมิด</h3>
		<p>1 ผลที่ตามมาจากการฟ้องร้อง การเรียกร้อง และ/หรือทำให้เขาเสียหายต่อชื่อเสียงของเรา
			อันเป็นผลมาจากคำแถลงที่ไม่เป็นความจริงหรือการละเมิดข้อผูกพันในคำแถลงของคุณ
			คุณจะต้องรับผิดชอบค่าใช้จ่ายทั้งทางตรงและทางอ้อม ความสูญเสีย และการชดเชยทั้งหมดที่เกิดขึ้นโดยเรา
			ซึ่งรวมถึงค่าใช้จ่ายที่เกี่ยวข้องและค่าทนายความที่เราจ่ายสำหรับการดำเนินคดี</p>
		<p>
			2. นอกเหนือจากที่ตกลงกันไว้เป็นอย่างอื่นในข้อตกลงนี้ การฝ่าฝืนข้อกำหนดใด ๆ ในข้อตกลงนี้
			หรือการให้บริการที่คุณให้นั้นไม่เป็นไปตามข้อกำหนดของเรา
			และเรามีสิทธิ์ที่จะใช้ข้อจำกัดหรือมาตรการลงโทษที่เกี่ยวข้องแต่เพียงฝ่ายเดียว ซึ่งรวมถึงแต่ไม่จำกัดเพียง การจำกัด
			การปิด การรีไซเคิล หรือการยุติการใช้ UniLive Live Live ของคุณ การจำกัดหรือหยุดบริการแยกต่างหาก (เช่น
			การถ่ายทอดสดผ่านวิดีโอ) และการตัดสินใจว่าจะกลับมาใช้หรือไม่ขึ้นอยู่กับสถานการณ์จริง
			การหักค่าบริการที่ยังไม่ได้ชำระในบัญชีของคุณ
		</p>
	</div>
</template>

<script>
export default {
	data() {
		return {
			options: [
				{
					value: '中文',
					label: '中文'
				}, {
					value: 'English',
					label: 'English'
				}, {
					value: '한국인',
					label: '한국인'
				}, {
					value: '日本語',
					label: '日本語'
				}, {
					value: 'Русский',
					label: 'Русский'//俄国
				}, {
					value: 'Tiếng Việt',
					label: 'Tiếng Việt'//越南
				}, {
					value: 'ภาษาไทย',
					label: 'ภาษาไทย'//泰国
				}
			],
			value: '',
			language: ''
		}
	},
	created() {
		// url获取参数
		this.language = this.$route.query.language;
		if (this.language == 'zh_CN') {
			this.$router.push({
				path: '/LiveAgreementZh'
			})
		}
		if (this.language == 'en_US') {
			this.$router.push({
				path: '/LiveAgreementEn'
			})
		}
		if (this.language == 'ko_KR') {
			this.$router.push({
				path: '/LiveAgreementKo'
			})
		}
		if (this.language == 'ja_JP') {
			this.$router.push({
				path: '/LiveAgreementJa'
			})
		}
		//俄语
		if (this.language == 'ru_RU') {
			this.$router.push({
				path: '/LiveAgreementRu'
			})
		}
		//越南语
		if (this.language == 'vi_VN') {
			this.$router.push({
				path: '/LiveAgreementVi'
			})
		}
		//泰语
		if (this.language == 'th_TH') {
			this.$router.push({
				path: '/LiveAgreementTh'
			})
		}
	},
	methods: {
		clickChange(value) {
			this.value = value;
			if (value == '中文') {
				this.$router.push({
					path: '/LiveAgreementZh'
				})
			}
			if (value == 'English') {
				this.$router.push({
					path: '/LiveAgreementEn'
				})
			}
			if (value == '한국인') {
				this.$router.push({
					path: '/LiveAgreementKo'
				})
			}
			if (value == '日本語') {
				this.$router.push({
					path: '/LiveAgreementJa'
				})
			}
			if (value == 'Русский') {
				this.$router.push({
					path: '/LiveAgreementRu'
				})
			}
			if (value == 'Tiếng Việt') {
				this.$router.push({
					path: '/LiveAgreementVi'
				})
			}
			if (value == 'ภาษาไทย') {
				this.$router.push({
					path: '/LiveAgreementTh'
				})
			}
		},
	}
}
</script>

<style>
.container {
	padding: 0.1rem 0.3rem;
}

.lang {
	text-align: right;
	margin-bottom: 0.3rem;
}

h1,
h2 {
	color: #333;
	font-size: 0.5rem;
}

h3 {
	margin-top: 20px;
	font-size: 0.4rem;
}

p {
	margin-bottom: 15px;
	font-size: 0.3rem;
}

li {
	font-size: 0.3rem;
}
</style>